@import './scss/main';

body,
html {
  margin: 0;
  padding: 0;
  font-family: $font-chat;
}

#byk-cs {
  display: flex;
  flex-flow: column nowrap;
  height: 100vh;
  position: relative;
}

.p-component {
  font-family: $font-chat;
}
