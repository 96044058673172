.byk-login {
  background-color: #003cff;
  display: flex;
  flex-flow: column wrap;
  height: 100vh;
  width: 100vw;
}

.byk-logo {
  max-width: 400px;
}

.byk-title {
  font-size: 22px;
  color: #fff;
  margin: 0 0 20px 0;
}

.byk-login-form {
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  margin-bottom: 1rem;
  justify-content: center;
}

.login {
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  justify-content: center;
}

.login-button {
  min-width: 200px;
}

.footer {
  align-items: center;
  background-color: #fff;
  display: flex;
  justify-content: center;
  padding: 16px 0;
}
